/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import Offer from '../komponents/Offer/Offer';

const Login = () => (
  <>
    <Header />
    <Offer
      bank_id={1}
      tasaDescuentoCredit={1.6}
      tasaDescuentoDebit={1.7}
      total_year_cost={1200}
      sector={2}
      userPage={false}
      contractDate="Not-applicable"
    />
    <Footer />
  </>
);

export default Login;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Tienda en linea - PuntoTerminal" />
    <title>Tienda en linea - PuntoTerminal.mx</title>
  </>
);
